<template>
  <div class="mt-14">
    <div
      class="collections"
      :data-qa="
        mode === 'render' ? 'collections-render' : 'collections-product'
      "
    >
      <div class="collections__header" v-if="isSearchActive">
        <div class="pa-0 collections__title">Коллекции</div>
        <div class="collections__action d-flex">
          <TipsVue :keyTips="'collections-search'">
            <v-btn icon color="primary" @click="isSearchActive = false"
              ><v-icon size="18">$vuetify.icons.search</v-icon></v-btn
            >
          </TipsVue>
          <PermissionWrapperVue
            :permissionCode="'create_collection'"
            :textStyles="{ padding: '14px' }"
            :iconStyles="{ top: '8px' }"
          >
            <TipsVue :keyTips="'collections-add'">
              <v-btn
                icon
                :data-qa="
                  mode === 'render'
                    ? 'main-menu-create-render'
                    : 'main-menu-create-collection'
                "
                class="collections__action__add"
                color="primary"
                @click="isCreateCollection = true"
                ><v-icon>mdi-view-grid-plus</v-icon></v-btn
              >
            </TipsVue>
          </PermissionWrapperVue>
        </div>
      </div>
      <div
        class="collections__search"
        v-else
        :style="{
          border: collectionSearch && collectionSearch.length == 0 ? 0 : '',
        }"
      >
        <v-autocomplete
          @update:search-input="getValueSearch"
          :items="searchValue ? collections : []"
          item-text="name"
          item-value="key"
          solo
          flat
          color="primary"
          class="collections__search-input"
          hide-details
          background-color="secondary"
          dense
          :menu-props="{ contentClass: 'collections__search-menu' }"
          placeholder="Введите название коллекции"
          @keypress.enter="searchCollections"
          hide-no-data
          autofocus
        >
          <template slot="append">
            <div class="d-flex align-center">
              <v-icon
                size="17"
                class="mr-2"
                color="primary"
                @click="searchCollections"
                v-show="searchValue"
              >
                $vuetify.icons.search
              </v-icon>
              <v-icon color="primary" size="18" @click="closeSearchCollection">
                $vuetify.icons.closeRounded
              </v-icon>
            </div>
          </template>
        </v-autocomplete>
        <div v-if="searchText" class="result-search">
          <div
            v-if="collectionSearch && collectionSearch.length == 0"
            class="mt-3"
          >
            По запросу
            <span class="result-search-bold">{{ searchValueForReplace }}</span>
            ничего не найдено
          </div>
          <div v-else class="mt-3">
            Результаты поиска
            <span class="result-search-bold">{{ searchValueForReplace }}</span>
          </div>
        </div>
      </div>
      <div
        class="collections__subheader"
        v-if="collectionSearch && collectionSearch.length !== 0"
      >
        <button
          class="collections__subheader-item"
          @click="selectAllCollection"
          :ripple="false"
          :disabled="selectedCollection.length === collectionSearch.length"
        >
          Выделить все
        </button>
        <div class="collections__subheader-line"></div>
        <button
          class="collections__subheader-item"
          :disabled="!selectedCollection.length"
          @click="
            selectedCollection = [];
            searchValueForReplace = '';
          "
        >
          Снять выделение
        </button>
      </div>
      <div
        class="d-flex justify-center align-center pt-16"
        v-if="loadingCollections"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <!-- Список коллекций -->
      <v-list class="pt-0 collections__list" v-else>
        <v-list-item-group :value="selectedCollection" color="primary">
          <v-hover
            v-for="(collection, index) in allCollections"
            :key="index"
            v-slot="{ hover }"
          >
            <v-list-item
              class="collection-list-item"
              active-class="collection-list-item__active"
              :class="{
                'collection-list-item__active': selectedCollection.includes(
                  collection.key
                ),
              }"
              :value="collection.key"
              @click="toggleCollectionByList(collection.key)"
            >
              <div class="collection-list-item__content d-flex align-center">
                <div class="checkbox-wrapper">
                  <v-checkbox
                    v-show="
                      (selectedCollection.includes(collection.key) &&
                        selectedCollection.length > 1) ||
                      hover
                    "
                    class="mt-0 pt-0"
                    hide-details
                    v-model="selectedCollection"
                    :value="collection.key"
                    @click.stop="() => {}"
                    on-icon="$vuetify.icons.trueCheckBox"
                    off-icon="$vuetify.icons.falseCheckBox"
                    :ripple="false"
                  >
                  </v-checkbox>
                </div>
                <p style="flex-grow: 2; width: 160px" class="text-truncate">
                  {{ collection.name }}
                </p>
                <v-menu
                  rounded="zarbo"
                  left
                  content-class="rounded-lg elevation-0 collections__menu"
                  v-if="mode !== 'render' && mode !== 'ai-photo'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="collections__choise__append-wrapper">
                      <v-btn
                        v-show="hover"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                        class="collections__choise__append"
                      >
                        <v-icon color="primary">mdi-menu</v-icon>
                      </v-btn>
                    </div>
                  </template>
                  <v-list dense>
                    <v-list-item-group>
                      <v-hover v-slot="{ hover }" open-delay="200">
                        <PermissionWrapperVue
                          :billingValue="mode === 'render' ? '' : productsCount"
                          :permissionCode="
                            mode === 'render'
                              ? 'create_render360'
                              : 'create_product'
                          "
                          :billingCode="
                            mode === 'render' ? 'render360' : 'product_count'
                          "
                        >
                          <v-list-item
                            :data-qa="
                              mode === 'render'
                                ? 'create-render'
                                : 'create-product'
                            "
                            @click="createProductFromCollection(collection)"
                            :disabled="selectedCollection === null"
                          >
                            <v-list-item-icon class="mr-3 my-auto">
                              <v-icon :color="hover ? 'primary' : ''" size="18"
                                >$vuetify.icons.createProduct</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-title class="pl-1"
                              >Создать продукт</v-list-item-title
                            >
                          </v-list-item>
                        </PermissionWrapperVue>
                      </v-hover>

                      <v-hover v-slot="{ hover }" open-delay="200">
                        <PermissionWrapperVue
                          :permissionCode="
                            mode === 'render'
                              ? 'create_render360'
                              : 'update_collection'
                          "
                        >
                          <v-list-item
                            @click="beforeEditCollection(collection)"
                          >
                            <v-list-item-icon class="mr-3 my-auto">
                              <v-icon :color="hover ? 'primary' : ''" size="18"
                                >$vuetify.icons.edit</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-title
                              class="pl-1"
                              :data-qa="
                                mode === 'render'
                                  ? 'rename-collection-render'
                                  : 'rename-collection-product'
                              "
                              >Переименовать</v-list-item-title
                            >
                          </v-list-item>
                        </PermissionWrapperVue>
                      </v-hover>

                      <v-hover v-slot="{ hover }" open-delay="200">
                        <PermissionWrapperVue
                          :permissionCode="
                            mode === 'render'
                              ? 'create_render360'
                              : 'delete_collection'
                          "
                        >
                          <v-list-item
                            :data-qa="
                              mode === 'render'
                                ? 'delete-collection-render'
                                : 'delete-collection-product'
                            "
                            @click="beforeDeleteCollection(collection)"
                          >
                            <v-list-item-icon class="mr-3 my-auto">
                              <v-icon
                                style="margin-left: -4px"
                                :color="hover ? 'red' : ''"
                                size="24"
                                >mdi-trash-can</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-title class="pl-1"
                              >Удалить</v-list-item-title
                            >
                          </v-list-item>
                        </PermissionWrapperVue>
                      </v-hover>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <div
                  v-if="mode === 'render' || mode === 'ai-photo' || !hover"
                  class="collections__productcount"
                >
                  {{ collection.count ? collection.count : 0 }}
                </div>
              </div>
            </v-list-item>
          </v-hover>
        </v-list-item-group>
      </v-list>
    </div>

    <!-- Создать коллекцию -->
    <v-dialog
      v-model="isCreateCollection"
      max-width="520px"
      content-class="create-collection-dialog"
      @keydown.esc="isCreateCollection = false"
    >
      <CreateCollectionVue
        @update-collection="getCollections()"
        @close-popup="isCreateCollection = false"
      ></CreateCollectionVue>
    </v-dialog>

    <!-- редактирование коллекций -->
    <v-dialog
      max-width="600"
      v-model="isConfirmEditCollection"
      transition="dialog-bottom-transition"
      @keydown.esc="isConfirmEditCollection = false"
      content-class="create-collection-dialog"
    >
      <EditCollectionVue
        :collection="collectionToEdit"
        @close-popup="isConfirmEditCollection = false"
      ></EditCollectionVue>
    </v-dialog>

    <!-- удаление коллекции -->
    <v-dialog
      max-width="600"
      v-model="isConfirmDeleteCollection"
      transition="dialog-bottom-transition"
      @keydown.esc="isConfirmDeleteCollection = false"
      content-class="create-collection-dialog"
    >
      <DeleteCollectionVue
        :collection="collectionToDelete"
        @get-colllections="getCollections"
        @close-popup="isConfirmDeleteCollection = false"
      ></DeleteCollectionVue>
    </v-dialog>
  </div>
</template>

<script>
import CreateCollectionVue from "../Product/Collections/CreateCollectionVue.vue";
import EditCollectionVue from "../Product/Collections/EditCollectionVue.vue";
import DeleteCollectionVue from "../Product/Collections/DeleteCollectionVue.vue";
import PermissionWrapperVue from "../Utils/PermissionWrapperVue.vue";
import TipsVue from "../Utils/TipsVue.vue";
import { eventBus } from "@/main";
import { mapState } from "vuex";

export default {
  name: "menu-coolections",

  components: {
    CreateCollectionVue,
    EditCollectionVue,
    DeleteCollectionVue,
    PermissionWrapperVue,
    TipsVue,
  },

  data: () => ({
    isSearchActive: true,
    searchText: false,
    searchValueForReplace: "",
    collections: null,
    isCreateCollection: false,
    selectedCollection: [],
    loadingCollections: false,
    searchValue: "",
    isConfirmEditCollection: false,
    isConfirmDeleteCollection: false,
    collectionToEdit: {},
    collectionToDelete: {},
  }),
  computed: {
    ...mapState({
      user: (state) => state.appStore.user,
      selectedTeam: (state) => state.appStore.selectedTeam,
      mode: (state) => state.renderStore.mode,
      productsCount: (state) => state.productsStore.totalProducts,
      allCollections: (state) => state.appStore.collections,
    }),
    collectionSearch() {
      if (this.searchValueForReplace !== "") {
        return this.collections?.filter(
          (item) =>
            item.name
              ?.toLowerCase()
              .indexOf(this.searchValueForReplace?.toLowerCase()) !== -1
        );
      }
      return this.collections;
    },
  },

  watch: {
    loadingCollections(newVal) {
      eventBus.$emit("update-loading-collections", newVal);
    },

    selectedCollection(newVal, oldVal) {
      let lastCollections = localStorage.getItem("zarbo-selected-collection");
      if (lastCollections) {
        lastCollections = JSON.parse(lastCollections);
        lastCollections[this.user.user_id] = newVal;
        localStorage.setItem(
          "zarbo-selected-collection",
          JSON.stringify(lastCollections)
        );
      } else {
        let obj = {};
        obj[this.user.user_id] = newVal;
        localStorage.setItem("zarbo-selected-collection", JSON.stringify(obj));
      }
      if (newVal.length === 0) {
        delete lastCollections[this.user.user_id];
        localStorage.setItem(
          "zarbo-selected-collection",
          JSON.stringify(lastCollections)
        );
      }
      this.$store.dispatch("SET_VALUE", {
        key: "selectedCollection",
        value: newVal,
      });
      // Обнуляем значение для блокировки кнопки Далее в АИ-фото при смене коллекции
      if (this.mode === "ai-photo") {
        if (newVal.toString() !== oldVal.toString()) {
          this.$store.dispatch("SET_VALUE", {
            key: "productId",
            value: null,
          });
        }
      }
    },
  },

  async mounted() {
    await this.getCollections();
    eventBus.$on("update-all-collections", (bool) => this.getCollections(bool));
  },

  methods: {
    getValueSearch(val) {
      if (val) {
        this.searchValue = val;
      } else {
        this.searchValue = "";
      }
    },

    beforeDeleteCollection(collection) {
      this.collectionToDelete = collection;
      this.isConfirmDeleteCollection = true;
    },

    searchCollections() {
      if (this.searchValue && this.searchValue !== "") {
        this.searchValueForReplace = this.searchValue;
        this.selectAllCollection();
        this.searchText = true;
      }
    },

    selectAllCollection() {
      this.selectedCollection = [];
      this.collectionSearch?.forEach((collection) => {
        this.selectedCollection.push(collection.key);
      });
    },

    toggleCollectionByList(e) {
      this.isCheckboxMode = false;
      this.selectedCollection.splice(0, this.selectedCollection.length, e);
    },

    createProductFromCollection(collection) {
      this.$store.dispatch("SET_VALUE", {
        key: "collectionToCreate",
        value: collection,
      });
      eventBus.$emit("open-create-master");
    },

    async getCollections(anotherTeam) {
      this.loadingCollections = true;
      let url;

      if (localStorage.getItem("selectedTeam")) {
        url =
          this.$config.BASE_URL +
          "/api/v1/collections/?team=" +
          localStorage.getItem("selectedTeam");
      } else {
        url = this.$config.BASE_URL + "/api/v1/collections/";
      }
      try {
        const resp = await this.$axios.get(url);
        this.collections = resp.data ? resp.data : [];
        this.$store.dispatch("SET_VALUE", {
          key: "collections",
          value: this.collections,
        }); // загрузил в хранилище все коллекции (использую при создании продукта)
        this.loadingCollections = false;

        // выбор выбранных пользователем коллекци(при выходе\обновлении)
        let lastCollections = localStorage.getItem("zarbo-selected-collection");
        lastCollections = JSON.parse(lastCollections);
        let selectedCollectionsSaved =
          lastCollections && lastCollections[this.user.user_id]
            ? lastCollections[this.user.user_id]
            : null;
        if (
          lastCollections &&
          selectedCollectionsSaved &&
          selectedCollectionsSaved.length
        ) {
          selectedCollectionsSaved.forEach((savedColl) => {
            let collctionExist = resp.data.find(
              (coll) => coll.key === savedColl
            ); // проверяем что коллекция существует(могли удалить)
            const alreadySelected = this.selectedCollection.find(
              (col) => col === savedColl
            ); // Флаг, что коллекция уже выбрана, чтобы убрать дублирование
            if (collctionExist && !alreadySelected) {
              this.selectedCollection.push(savedColl);
            }
          });
        }
        // если после всех махинаций ничего не выбранно, то выбрать последнюю
        if (this.selectedCollection.length === 0) {
          let lastCollectionKey =
            resp.data && resp.data[resp.data.length - 1]
              ? resp.data[resp.data.length - 1].key
              : null;
          this.selectedCollection.push(lastCollectionKey);
        }
      } catch (error) {
        console.error("fail get collections", error);
      }
      this.isCreateCollection = false;

      if (anotherTeam) {
        this.selectAllCollection();
      }
    },

    closeSearchCollection() {
      this.searchText = false;
      this.isSearchActive = true;
      this.searchValueForReplace = "";
    },

    beforeEditCollection(collection) {
      this.collectionToEdit = collection;
      this.isConfirmEditCollection = true;
    },
  },
};
</script>

<style lang="scss">
.collections {
  &__list {
    height: calc(100vh - 158px);
    overflow-y: auto;
  }
  &__productcount {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4e4087;
    padding: 3px 8px;
    background: #efefff;
    border-radius: 5px;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 24px 20px;
    border-bottom: 1px solid #e0e0e0;
  }
  &__title {
    font-weight: 700;
    font-size: 20px;
    color: #212121;
  }
  &__action {
    &__add {
      transform: scale(1, -1);
    }
  }
  &__subheader {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    &-item {
      cursor: pointer;
      color: #4e4087;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      -moz-user-select: none;
      -khtml-user-select: none;
      user-select: none;
    }
    &-item:disabled,
    &-item[disabled] {
      color: #919095;
    }
    &-line {
      border-right: 1px solid #e0e0e0;
    }
  }
  &__search {
    padding: 12px;
    border-bottom: 1px solid #e0e0e0;
    z-index: 2;
    &-input {
      border-radius: 10px;
    }
    .v-select__slot {
      height: 44px;
      input {
        &::placeholder {
          color: #7f75a5;
          font-size: 15px;
        }
        color: #232451;
        font-size: 15px;
      }
    }
    .v-input__slot {
      z-index: 20;
    }
  }
  .v-menu__content {
    box-shadow: none;
  }
  &__choise {
    &__append-wrapper {
      width: 34px;
      height: 100%;
    }
    &__append {
      padding: 17px;
      border-radius: 10px;
      &:hover::before {
        opacity: 1 !important;
      }
      &::before {
        background: #efefff;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .collection-list-item {
    color: var(--v-primary-base) !important;
    .collection-list-item__content {
      width: 100%;
    }
    .checkbox-wrapper {
      width: 41px;
      height: 41px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__active {
      background-color: var(--v-secondary-base);
      font-weight: 700;
      border-bottom: none;
    }
  }
}
.collections__menu {
  .v-list {
    background-color: #f5f5f5 !important;
    .v-list-item:hover {
      background-color: #e1e1e1;
    }
    .v-list-item-icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.collections__search-menu {
  border-radius: 10px !important;
  margin-top: -10px;
  z-index: 1 !important;
  box-shadow: inherit;
  color: #7e7fba;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  .v-list {
    background: #eaeaff;
    border-radius: 10px;
    padding-top: 20px;
  }
  .v-list-item__title {
    font-size: 15px !important;
    font-weight: 400 !important;
  }
}
.collections__search-input {
  .v-input__slot {
    padding: 0 16px !important;
  }
}
.collection-list-item__active {
  &::after {
    display: none;
  }
}
.collection-list-item {
  position: relative;
  .v-input--selection-controls__input:hover {
    background: #c5c4f6;
    border-radius: 10px;
    width: 30px;
    height: 30px;
  }
  &:hover {
    background: #dddcff;
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
.collections .v-list-item--link:before {
  display: none;
}
</style>
