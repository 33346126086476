export default {
  state: {
    mode: null,
    selectedModelForRender: null,
    renderStep: 1,
    renderName: null,
    render: null,
    renderSearch: null,
    // materialRender: '', // переименован в shadersRender
    shadersRender: [],
    loadRenderData: false,
    isRenderWidgetLoaded: false,
    renderTab: 0,
    renderChangeScaleWhilePreview: false,
    renderChangeHDRIWhilePreview: false,
    isUseBuildInMaterial: false,
  },
  mutations: {
    setValueModule (state, keyValue) {
      // console.log('set value module render')
      if (keyValue.key in state) {
        state[keyValue.key] = keyValue.value
      }
    },
    setValue (state, keyValue) {
      state[keyValue.key] = keyValue.value
    },
    setMaterialRender (state, data) { // data - { shader: Obj, material: Obj }
      let _shadersRender = [...state.shadersRender]
      _shadersRender = _shadersRender.filter(_shader => {
        return _shader.id !== data.shader.id
      }) // прежде чем добавить материал, убираем ранее добавленный шейдер
      _shadersRender.push({
        ...data.shader,
        render_material: data.material
      })
      state.shadersRender = _shadersRender
    },
    deleteMaterialRender (state, shader) {
      let _shadersRender = [...state.shadersRender]
      _shadersRender = _shadersRender.filter(_shader => {
        return _shader.id !== shader.id
      })
      state.shadersRender = _shadersRender
    }
  },
  actions: {
    // тот же SET_VALUE, только пишет лишь в свой модуль хранилища, а не во все. Хочу его использовать как SET_VALUE, но страшно сразу менять во всем проекте 
    SET_VALUE_MODULE ({commit}, keyValue) {
      commit('setValueModule', keyValue)
    },
    SET_VALUE ({ commit }, keyValue) {
      commit('setValue', keyValue)
    },
    SET_MATERIAL_RENDER({ commit }, data) {
      commit('setMaterialRender', data)
    },
    DELETE_MATERIAL_RENDER({ commit }, shader) {
      commit('deleteMaterialRender', shader)
    }
  }
}